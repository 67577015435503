import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFeature } from "@growthbook/growthbook-react";
import { Link } from "@remix-run/react";

import { DayZero } from "~/components/logos/day-zero";

export function Announcement(): JSX.Element | null {
  const { value: siteAnnouncementBar } = useFeature<{
    date: string;
    subheading: string;
    cta: string;
    link: string;
  }>("site-announcement-bar");

  if (!siteAnnouncementBar) return null;

  return (
    <Link
      prefetch="intent"
      to={siteAnnouncementBar.link}
      className="py-4 bg-purple-600 text-white hover:bg-purple-700 text-sm global-hover-transition"
    >
      <div className="container lg:flex lg:justify-between lg:items-center lg:gap-x-6">
        <div className="flex flex-col justify-start lg:flex-row lg:items-center gap-x-6 gap-y-4">
          <div className="flex items-center gap-x-6">
            <DayZero className="h-3.5 w-auto" />
            <div className="rounded-full h-2 w-2 bg-purple-300" />
            <span>{siteAnnouncementBar.date}</span>
          </div>
          <div className="rounded-full h-2 w-2 bg-purple-300 hidden lg:block" />
          <span className="mb-2 lg:mb-0">{siteAnnouncementBar.subheading}</span>
        </div>
        <div className="flex items-center lg:justify-center gap-x-2 font-medium whitespace-nowrap">
          <span>{siteAnnouncementBar.cta}</span>
          <FontAwesomeIcon icon={faArrowRight} className="h-[14px]" />
        </div>
      </div>
    </Link>
  );
}

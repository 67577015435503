import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react";
import { motion } from "framer-motion";

import { Announcement } from "~/components/site/announcement";
import { Footer } from "~/components/site/footer";
import { Header, UnThemedHeader } from "~/components/site/header";

type Props = Readonly<{
  children: React.ReactNode;
  themed?: boolean;
}>;

export const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
      ease: "easeInOut",
    },
  },
};

export const item = {
  hidden: { y: 25, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export function Layout({ children, themed = true }: Props): JSX.Element {
  const { ready } = useGrowthBook();
  const showAnnouncement = useFeatureIsOn("site-announcement-bar");

  if (!ready) {
    return <div className="h-screen bg-primary"></div>;
  }

  return (
    <>
      {showAnnouncement && <Announcement />}
      {themed ? <Header /> : <UnThemedHeader />}
      <motion.main
        variants={container}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="prose dark:prose-invert grow"
      >
        {children}
      </motion.main>
      <Footer />
    </>
  );
}
